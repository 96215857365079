/* styles.css */

/* Styles généraux */
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

/* Styles du header */
.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #000
}

.social {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: end;
  background: #000;
  color: #fff;
}

.social svg {
  margin-right: 10px;
  margin-left: 20px;
}

.menu {
  display: flex;
  align-items: center;
}

.logo {
  width: 100px;
}

.menu nav ul {
  list-style: none;
  display: flex;

}

.menu nav ul li {
  margin-right: 20px;
}

.menu nav ul li a {
  text-decoration: none;
  color: #333;
}

.search {
  display: flex;
  align-items: center;
}

.search input[type="text"] {
  padding: 5px;
  margin-right: 10px;
}

/* Styles de la section de l'image */
.homeImage {
  width: 100%;
  height: auto;
  top: 3rem;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

}

.content {
  position: absolute;
  top: 0;
  font-family: 'Montserrat', sans-serif;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
}


.content h1 {
  text-align: center;
  font-weight: 700;
  color: #ffffff;
  font-size: 45px;
}

.positionButton {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.positionButton a {
  text-decoration: none;
}

.button {
  padding: 15px 10px 15px 10px;
  background-color: #2c99c4;
  border: none;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  width: 80%;
  font-size: 20px;
}

.header {
  background-color: #b61b1b;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .1);
  width: 100%;
  z-index: 3;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;
}

.header li a {
  display: block;
  padding: 20px 20px;
  border-right: 1px solid #f4f4f4;
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: #f4f4f4;
}

.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked~.menu {
  max-height: 240px;
}

.header .menu-btn:checked~.menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked~.menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked~.menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* section */

.container {
  width: 100%;
  margin-top: 4rem;
}

.section {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 4%;
  width: 100%;
}

.section2 {
  display: flex;
  background-color: #ddd;
  /* Fond gris pour la section */
  padding: 20px;
}

.image {
  border-radius: 6px;
  width: 90%;
  /* margin-top: 1rem; */
  display: flex;
  position: relative;
  margin: auto 10%;
}

.headerP {
  text-align: center;
  margin: 20px 0;
  position: relative;
  display: flex;
  width: 50%;
  font-size: 20px;
  font-weight: 500;
}

.textBlock {
  width: 100%;
  margin: 5%;
}

.title {
  font-family: 'Montserrat', sans-serif;
  text-align: start;
  font-weight: 600;
  margin-top: 2%;
}

.paragraph {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  text-align: start;
  font-weight: 500;
  margin-top: 2%;
}

.backgroundGrey {
  background-color: #ddd;
  /* Couleur de fond gris pour la deuxième section */
}

.diplomaSection {
  text-align: center;
  /* Centre le texte et les éléments */
  margin: 50px 0;
  /* Ajoute de l'espace avant et après la section */
}

.titles {
  color: #000;
}

.diplomaTitle {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
}

.diplomaDescription {
  margin-bottom: 40px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: 500;
}

.diplomaList {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.diplomaItem {
  background: #ddd;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: 200px;
  margin: 0 10px;
}

.diplomaItem:hover {
  transform: translateY(-5px);
  background-color: #eee;
}

.diplomaCLick {
  text-decoration: none;

}

.diplomaItem h3 {
  margin-top: 10%;
  font-size: 15px;
}

.diplomaIcon {
  width: 80px;
  /* Taille des icônes des diplômes */
  margin-bottom: 10px;
  /* Espace après l'icône */
}

.licenseSection {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin: 50px 0;
}

.licenseTitle {
  font-size: 2.5em;
  margin-bottom: 20px;

}

.licenseDescription {
  margin-bottom: 40px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: 500;
}

.accreditation {
  display: flex;
  position: absolute;
  left: 85%;
  width: 100px;
  z-index: -999;
  margin-top: 18%;
}

.programsContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.program {
  background-color: #17a2b8;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  max-width: 100vw;
  margin: 0 10px;
  transition: transform 0.3s ease;
}

.program h3 {
  font-size: 20px;
}

.program:hover {
  transform: translateY(-5px);
  /* Effet de survol pour les cartes */
}

.programIcon {
  width: 100px;
  /* Taille de l'icône */
  margin-bottom: 20px;
}

.programButton {
  background-color: #fff;
  /* Bouton en blanc */
  color: #17a2b8;
  /* Texte du bouton en couleur de fond de la carte */
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.programButton:hover {
  background-color: #e0e0e0;
  /* Changement de couleur de fond au survol */
}

.studentLifeSection {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin: 5% 0;
}

.studentLifeTitle {
  font-size: 2.5em;
  margin-bottom: 2%;
}

.studentLifeDescription {
  margin-bottom: 4%;
}

.studentLifeImages {
  display: flex;
  flex-direction: column;
}

.studentLifeImage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.div1,
.div2,
.div3,
.div4 {
  margin: 1%;
}

.life1,
.life2,
.life3,
.life4 {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.div1 {
  grid-area: 1 / 1 / 3 / 2;
}

.div2 {
  grid-area: 1 / 2 / 2 / 3;
}

.div3 {
  grid-area: 2 / 2 / 3 / 3;
}

.div4 {
  grid-area: 1 / 3 / 3 / 4;
}


.downloadAppSection {
  font-family: 'Montserrat', sans-serif;
  background-color: #2c99c4;
  color: #ffffff;
  text-align: center;
  padding: 40px 0;
}

.downloadAppTitle {
  margin-bottom: 40px;
  font-size: 2em;
}

.phonesContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.phoneImage {
  max-width: 365px;
  margin: 0 20px;
}

.badgesContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.storeBadge {
  height: 50px;
  cursor: pointer;
}


@media (max-width: 768px) {

  .downloadAppSection {
    font-family: 'Montserrat', sans-serif;
    background-color: #2c99c4;
    color: #ffffff;
    text-align: center;
    padding: 40px 0;
  }

  .downloadAppTitle {
    font-size: 1.5em;
    margin-bottom: 2%;
  }

  .accreditation {
    display: flex;
    position: absolute;
    left: 75.5%;
    width: 100px;
    z-index: -999;
    margin-top: -88px;
  }

  .accreditationD {
    display: flex;
    position: absolute;
    left: 78%;
    width: 82px;
    z-index: 1;
    margin-top: -355px;
  }

  .accreditationL {
    display: flex;
    position: absolute;
    left: 75%;
    width: 100px;
    z-index: -999;
    margin-top: -148px;
  }

  .content {
    position: absolute;
    font-size: 12px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    padding: 20px;
  }

  .content p {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
  }

  .content h1 {
    text-align: center;
    font-weight: 900;
    color: #ffffff;
    font-size: 24px;
  }

  .positionButton {
    margin: 0 auto;
    width: 100%;
  }

  .positionButton a {
    text-decoration: none;
  }

  .button {
    margin-top: 2%;
    padding: 15px 12px;
    background-color: #2c99c4;
    border: none;
    border-radius: 10px;
    color: #fff;
    display: inline;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    font-size: 18px;

  }

  .title {
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    font-weight: 600;
    margin-top: 2%;
    font-size: 30px;
  }

  .paragraph {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    text-align: start;
    font-weight: 500;
    margin-top: 2%;
    padding: 8px;
  }

  .diplomaDescription {
    margin-bottom: 15px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    padding: 17px;
  }

  .diplomaItem {
    background: #e7e7e7b5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 200px;
    margin: 0 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .licenseDescription {
    margin-bottom: 15px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    padding: 17px;
  }

  .diplomaList {
    flex-direction: column;
  }

  .programsContainer {
    flex-direction: column;
  }

  .program {
    width: 80%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .phonesContainer,
  .badgesContainer {
    flex-direction: column;
  }

  .phoneImage,
  .storeBadge {
    margin: 10px 0;
  }
}

@media (min-width: 1121px) {

  .accreditation {
    display: flex;
    position: absolute;
    left: 75.5%;
    width: 100px;
    z-index: -999;
    margin-top: -88px;
  }

  .accreditationD {
    display: flex;
    position: absolute;
    left: 64%;
    width: 82px;
    z-index: 1;
    margin-top: -14.5rem;
  }

  .accreditationL {
    display: flex;
    position: absolute;
    left: 66%;
    width: 82px;
    z-index: 1;
    margin-top: -5.5rem;
  }
}

@media (min-width: 1119px) and (max-width:1120px) {
  .accreditation {
    display: flex;
    position: absolute;
    left: 75.5%;
    width: 100px;
    z-index: -999;
    margin-top: -88px;
  }

  .accreditationD {
    display: flex;
    position: absolute;
    left: 69%;
    width: 82px;
    z-index: 1;
    margin-top: -16.5rem;
  }

  .accreditationL {
    display: flex;
    position: absolute;
    left: 72%;
    width: 82px;
    z-index: 1;
    margin-top: -5.5rem;
  }
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }

  .header li a {
    padding: 20px 30px;
    color: #000;
    font-weight: bolder;
  }

  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }

  .header .menu-icon {
    display: none;
  }
}

@media (min-width :1559px) {
  .headerImage {
    display: block;
    position: relative;
    margin-top: 4.5%;
    width: 1600px;
  }
}

.headerImage {
  display: block;
  position: relative;
  margin-top: 3.5%;
  width: 1700px;
}