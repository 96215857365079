/* styles.css */

/* Styles généraux */
body {
    margin: 0;
}

.home {
    font-family: 'Montserrat', sans-serif;
}

/* Styles du header */
.header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #000
}

.social {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: end;
    background: #000;
    color: #fff;
}

.social svg {
    margin-right: 10px;
    margin-left: 20px;
}

.menu {
    display: flex;
    align-items: center;
}

.logo {
    width: 100px;
}

.menu nav ul {
    list-style: none;
    display: flex;

}

.menu nav ul li {
    margin-right: 20px;
}

.menu nav ul li a {
    text-decoration: none;
    color: #333;
}

.search {
    display: flex;
    align-items: center;
}

.search input[type="text"] {
    padding: 5px;
    margin-right: 10px;
}

/* Styles de la section de l'image */
.homeImage {
    height: 10rem;
    text-align: center;
    display: block;
    background: #49abcb;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
}

.content p {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    width: 50%;
}

.content h1 {
    text-align: center;
    font-weight: 900;
    color: #ffffff;
    font-size: 30px;
}

/* section */

.container {
    width: 100%;
    margin: auto;
}

.section {
    display: flex;
    align-items: flex-start;
    /* Alignement vertical en haut */
    justify-content: center;
    margin-bottom: 20px;
    /* Séparation entre les sections */
    margin-top: 20px;
}

.section2 {
    display: flex;
    background-color: #ddd;
    /* Fond gris pour la section */
    padding: 20px;
}

.image {
    border-radius: 6px;
    width: 35%;
    height: auto;
    display: block;
    margin-top: 5%;
}

.textBlock {
    width: 45%;
    margin-top: 3%;
    margin-left: 4%;
    padding: 20px;
}

.title {
    font-size: 2em;
    margin-bottom: 0.5em;
    text-align: start;
}

.paragraph {
    text-align: start;
    font-size: 20px;
    width: 90%;
}

.backgroundGrey {
    background-color: #ddd;
    /* Couleur de fond gris pour la deuxième section */
}

.valuesSection {
    text-align: center;
    padding: 40px 0;
    margin-bottom: 15px;
}

.valuesTitle {
    font-size: 2em;
    margin-bottom: 1em;
}

.valuesContainer {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;
}

.valueCard {
    background: #f7f7f7;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 20%;
    gap: 2px;
}

.valueIcon {
    width: 80px;
    margin-bottom: 20px;
}

.valueTitle {
    font-size: 1em;
    color: #333333;
}

@media (min-width:320px) and (max-width:479px) {
    .image {
        display: none;
    }

    .textBlock {
        width: 100%;
        margin-top: 3%;
        margin-left: 4%;
        padding: 20px;
    }

    .paragraph {
        text-align: start;
        font-size: 20px;
        width: 99%;
    }

    .valueCard {
        background: #f7f7f7;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 80%;
        gap: 2px;
    }

    .content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        text-align: center;
        margin-top: -11px;
    }
}

@media (min-width:480px) and (max-width:599px) {
    .image {
        display: none;
    }

    .textBlock {
        width: 100%;
        margin-top: 3%;
        margin-left: 4%;
        padding: 20px;
    }

    .paragraph {
        text-align: start;
        font-size: 20px;
        width: 99%;
    }


    .valueCard {
        background: #f7f7f7;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 80%;
        gap: 2px;
    }
}

@media (min-width:600px) and (max-width: 800px) {
    .image {
        display: none;
    }

    .textBlock {
        width: 100%;
        margin-top: 3%;
        margin-left: 4%;
        padding: 20px;
    }

    .paragraph {
        text-align: start;
        font-size: 20px;
        width: 99%;
    }


    .valueCard {
        background: #f7f7f7;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 80%;
        gap: 2px;
    }
}

@media (max-width: 768px) {
    .image {
        display: none;
    }

    .valuesContainer {
        flex-direction: column;
    }

    .valueCard {
        margin-bottom: 20px;
    }
}

@media (min-width: 1280px) {
    .content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        text-align: center;
        margin-top: 60px;
    }
}

@media (min-width: 48em) {
    .header li {
        float: left;
    }

    .header li a {
        padding: 20px 30px;
        color: #000;
        font-weight: bolder;
    }

    .header .menu {
        clear: none;
        float: right;
        max-height: none;
    }

    .header .menu-icon {
        display: none;
    }
}