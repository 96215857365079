* {
    margin: 0;
    padding: 0;
}

a {
    color: #fff;
    text-decoration: none;
}

.pg_footer {
    font-family: 'Montserrat', sans-serif;
}


.footer {
    background-color: #000000;
    color: #fff;
}

.footer_wave_svg {
    background-color: transparent;
    display: block;
    height: 30px;
    position: relative;
    top: -1px;
    width: 100%;
}

.footer_wave_path {
    fill: #fffff2;
}

.footer_content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 450px;
    position: relative;
}

.footer_content_column {
    box-sizing: border-box;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    color: #fff;
}

.footer_content_column ul li a {
    color: #fff;
    text-decoration: none;
}

.footer_logo_link {
    display: block;
    width: 40%;
}

.footer_menu {
    margin-top: 30px;
}

.footer_menu_name {
    color: #fffff2;
    font-size: 15px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 2rem;
    text-transform: uppercase;
    text-align: start;
}

.footer_menu_list {
    list-style: none;
    margin-bottom: 0;
    margin-top: 10px;
    padding-left: 0;
}

.footer_menu_list li {
    margin-top: 5px;
}

.footer_call_to_action_description {
    color: #fffff2;
    margin-top: 10px;
    margin-bottom: 20px;
}

.footer_call_to_action_button:hover {
    background-color: #fffff2;
    color: #00bef0;
}

.button:last-of-type {
    margin-right: 0;
}

.footer_call_to_action_button {
    background-color: #027b9a;
    border-radius: 21px;
    color: #fffff2;
    display: inline-block;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    padding: 12px 30px;
    margin: 0 10px 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .2s;
    cursor: pointer;
    position: relative;
}

.footer_call_to_action {
    margin-top: 30px;
}

.footer_call_to_action_title {
    color: #fffff2;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
    margin-top: 2rem;
}

.footer_call_to_action_link_wrapper {
    margin-bottom: 0;
    margin-top: 10px;
    color: #fff;
    text-decoration: none;
}

.footer_call_to_action_link_wrapper a {
    color: #fff;
    text-decoration: none;
}





.footer_social_links {
    bottom: 0;
    height: 54px;
    position: absolute;
    right: 0;
    width: 236px;
}

.footer_social_amoeba_svg {
    height: 54px;
    left: 0;
    display: block;
    position: absolute;
    top: 0;
    width: 236px;
}

.footer_social_amoeba_path {
    fill: #027b9a;
}

.footer_social_link.linkedin {
    height: 26px;
    left: 3px;
    top: 11px;
    width: 26px;
}

.footer_social_link {
    display: block;
    padding: 10px;
    position: absolute;
}

.hidden_link_text {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
    top: 50%;
}

.footer_social_icon_svg {
    display: block;
}

.footer_social_icon_path {
    fill: #fffff2;
    transition: fill .2s;
}

.footer_social_link.twitter {
    height: 28px;
    left: 62px;
    top: 3px;
    width: 28px;
}

.footer_social_link.youtube {
    height: 24px;
    left: 123px;
    top: 12px;
    width: 24px;
}

.footer_social_link.github {
    height: 34px;
    left: 172px;
    top: 7px;
    width: 34px;
}

.footer_copyright {
    background-color: #027b9a;
    color: #fff;
    padding: 15px 30px;
    text-align: center;
}

.footer_copyright_wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.footer_copyright_text {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
}

.footer_copyright_link {
    color: #fff;
    text-decoration: none;
}

.badgesContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 1rem;
}

.storeBadge {
    height: 50px;
    /* Adjust based on your preference */
    cursor: pointer;
    /* Cursor changes to pointer on hover */
}

.accreditation {
    display: flex;
    position: absolute;
    left: 100%;
    width: 100px;
    z-index: 111;
    margin-top: 23px;
}



/* Media Query For different screens */
@media (min-width:320px) and (max-width:479px) {

    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .footer_content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 40px 15px 760px;
        position: relative;
    }
}

@media (min-width:480px) and (max-width:599px) {

    /* smartphones, Android phones, landscape iPhone */
    .footer_content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 40px 15px 776px;
        position: relative;
    }
}

@media (min-width:600px) and (max-width: 800px) {
    .footer {
        height: 1150px;
        background-color: #000000;
        color: #fff;
    }

    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .footer_content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 40px 15px 1050px;
        position: relative;
    }
}

@media (max-width: 768px) {
    .footer {
        height: 1125px;
        background-color: #000000;
        color: #fff;
    }
}

@media (min-width: 1120px) {
    .footer {
        margin-top: 5%;
        min-height: 662px;
        max-height: 900px;
        background-color: #000000;
        color: #fff;
    }

    .footer_logo {
        width: 25%;
    }

    .footer_logo_link {
        display: block;
        width: 40%;
    }

    .accreditation {
        display: flex;
        position: absolute;
        left: 84%;
        z-index: 0;
        margin-top: -40%;
        width: 8%;
    }
}