/* styles.css */

/* Styles généraux */
body {
    margin: 0;

}

/* Styles du header */

/* Styles de la section de l'image */
.homeImage {
    height: 10rem;
    text-align: center;
    display: block;
    background: #49abcb;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;

}

.content p {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    width: 50%;
    font-family: 'Montserrat', sans-serif;
}

.content h1 {
    text-align: center;
    font-weight: 900;
    color: #ffffff;
    font-size: 30px;
}

/* section */

.container {
    width: 100%;
    margin: auto;
}

.section {
    display: flex;
    align-items: flex-start;
    /* Alignement vertical en haut */
    justify-content: center;
    margin-bottom: 20px;
    /* Séparation entre les sections */
    margin-top: 20px;
    font-family: 'Montserrat', sans-serif;
}

.section2 {
    display: flex;
    background-color: #ddd;
    font-family: 'Montserrat', sans-serif;
    padding: 20px;
}

.image {
    border-radius: 6px;
    width: 80%;
    height: auto;
    display: block;
    margin-top: 5%;
}

.images {
    border-radius: 6px;
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.textBlock {
    text-align: start;
    width: 100%;
    margin-top: auto;
    margin-left: auto;
    margin-top: 2rem;
    position: relative;
    display: grid;
    padding-left: 10%;
}

.title {
    text-align: start;
    font-size: 2em;
    margin-bottom: 0.5em;
}

.paragraph {
    text-align: start;
    font-size: 20px;
    width: 90%;
}

.backgroundGrey {
    background-color: #ddd;
    /* Couleur de fond gris pour la deuxième section */
}

.valuesSection {
    text-align: center;
    padding: 40px 0;
}

.valuesTitle {
    font-size: 2em;
    margin-bottom: 1em;
}

.valuesContainer {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;
}

.valueCard {
    background: #f7f7f7;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 20%;
    gap: 2px;
}

.valueIcon {
    width: 80px;
    /* Icon width */
    margin-bottom: 20px;
    /* Space below the icon */
}

.valueTitle {
    font-size: 1em;
    color: #333333;
    /* Dark text for readability */
}

/* Media query pour un affichage réactif */
@media (max-width: 768px) {
    .valuesContainer {
        flex-direction: column;
    }

    .valueCard {
        margin-bottom: 20px;
        /* Space between cards when stacked */
    }
}

.sections {
    width: 90%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Montserrat', sans-serif;
}

.moduleCard {
    position: relative;
    width: 90%;
    height: 15%;
    margin: 2%;
}

.card_body {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.card_body h5 {
    font-size: 16px;
    font-weight: bold;
}


.card_title {
    width: 20%;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 2%;
}

.card_text {
    width: 60%;
    font-size: 15px;
    position: relative;
    display: flex;
    align-items: center;
    text-align: start;
    margin-top: auto;
    margin-bottom: auto;
}

.card_hours {
    width: 20%;
    color: #49abcb;
    font-size: 16px;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.section3 {
    margin-bottom: 2%;
    margin-top: 2%;
    width: 90%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Montserrat', sans-serif;
}

.unblockCard {
    margin-top: 2%;
    text-align: justify;
}

.unblock_title {
    font-family: 'Montserrat', sans-serif;
    display: inline;
    font-size: 15px;
}