.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Styles du header */
/* styles.css */

/* Styles généraux */
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

/* Styles du header */
.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #000;
}

.header.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.social {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: end;
  background: #000;
  color: #fff;
  width: 100%;
  position: fixed;
  z-index: 99999;

}

.social svg {
  margin-right: 10px;
  margin-left: 20px;
}

.menu {
  display: flex;
  align-items: center;
}

.menu nav ul {
  list-style: none;
  text-decoration: none;
  display: flex;

}

.menu nav ul li {
  margin-right: 20px;
}

.menu nav ul li a {
  text-decoration: none;
  color: #333;
}

.hamburger {
  display: none;
  cursor: pointer;
  font-size: 24px;
  padding: 10px;
}

.menu {
  list-style-type: none;
  text-align: center;
  display: flex;
  margin-top: 3.5%;
}

.search {
  display: flex;
  align-items: center;
}

.search input[type="text"] {
  padding: 5px;
  margin-right: 10px;
}

/* Styles de la section de l'image */
.homeImage {
  width: 100%;
  height: auto;
  margin-top: 1rem;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
}

.content p {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  width: 50%;
}

.content h1 {
  text-align: center;
  font-weight: 900;
  color: #ffffff;
  font-size: 30px;
}

/* .header {
  li {
    a {
      display: block;
      padding: 5px 0;
      text-decoration: none;
      font-family: 'Montserrat', sans-serif;

      &:hover {
        text-decoration: none;
        display: block;
        font-size: 16px;
        line-height: 1;
        font-weight: bold;
        position: relative;
        z-index: 1;
        text-align: center;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(to right, gray, #000 100%);
        background-size: 200% 100%;
        background-position: -100%;
        transition: all 0.3s ease-in-out;

        &:before {
          display: block;
          content: '';
          width: 0;
          height: 3px;
          bottom: 5px;
          left: 0;
          bottom: -3px;
          z-index: 0;
          position: absolute;
          background: black;
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          background-position: 0%;

          &:before {
            width: 100%;
          }
        }
      }
    }
  }
} */

/* section */

.container {
  width: 100%;
  margin: auto;
}

.section {
  display: flex;
  align-items: flex-start;
  /* Alignement vertical en haut */
  justify-content: center;
  margin-bottom: 20px;
  /* Séparation entre les sections */
  margin-top: 20px;
}

.section2 {
  display: flex;
  background-color: #ddd;
  /* Fond gris pour la section */
  padding: 20px;
}

.image {
  border-radius: 6px;
  width: 40%;
  height: auto;
  display: block;
  margin-top: 1rem;
}

.textBlock {
  width: 50%;
  /* Réglable selon la largeur souhaitée */
  padding: 20px;
}

.title {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.paragraph {
  font-size: 22px;
  letter-spacing: 1px;
}

.backgroundGrey {
  background-color: #ddd;
  /* Couleur de fond gris pour la deuxième section */
}





/* Styles pour le menu déroulant */
.dropdown {
  max-width: 13em;
  margin: 80px auto 0;
  position: relative;
  width: 100%;
}

.dropdown ul {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  /* Nouveau style pour la taille de la dropdown */
  width: 150px;
  height: 150px;
  overflow-y: auto;
  /* Pour activer le défilement si nécessaire */
  /* Ajout de styles pour afficher les éléments en colonne */
  display: flex;
  flex-direction: column;
  padding: 0;
  /* Supprimer les marges par défaut */
}

.dropdown:hover ul {
  display: block;
}

/* Styles spécifiques pour la dropdown des formations */
.dropdown-formations ul {
  top: 100%;
  left: 0;
}

/* Styles spécifiques pour la dropdown de l'espace société */
.dropdown-espace ul {
  top: 100%;
  left: 0;
}

.header {
  background-color: #eee;
  color: #000;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .1);
  width: 100%;
  list-style: none;
  position: absolute;
  z-index: 11111;
}


/* .header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #eee;
}


.header ul li ul li {
  background: #00000038;
  font-size: 16px;
  width: 209px;
  border: none;
  color: #fff9f9;
  display: block;
  justify-content: center;
  align-items: center;
  border-radius: 0.5em;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  height: 100px;
  margin: 6px;
}


.header ul li ul li a {
  position: absolute;
  display: flex;
  margin: 0px;
} */

/* test */
ul li {
  list-style-type: none;
  list-style: none;
}

a {
  text-decoration: none;
  list-style: none;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

nav {
  float: right;
}

nav ul li {
  position: relative;
  margin: 0;
  padding: 0;
}

nav ul li a {
  display: block;
  margin: 0;
  padding: 30px 15px;
  background-color: transparent;
  line-height: 16px;
  /* text-transform: uppercase; */
  text-align: center;
}

nav ul li a {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  transition: 0.4s ease all;
  -moz-transition: 0.4s ease all;
  -webkit-transition: 0.4s ease all;
}

nav ul li a * {
  color: #000;
  transition: 0.4s ease all;
  -moz-transition: 0.4s ease all;
  -webkit-transition: 0.4s ease all;
}

nav ul li a:focus,
nav ul li a:active,
nav ul li a:visited,
nav ul li a:link {
  color: #000;
  text-decoration: none;
}

nav ul li a:focus *,
nav ul li a:active *,
nav ul li a:visited *,
nav ul li a:link * {
  color: #000;
}

nav ul li a.active {
  color: #ea0000;
}

nav ul li a.active * {
  color: #ea0000;
}

nav ul li a:hover {
  color: #ea0000;
}

nav ul li a:hover * {
  color: #ea0000;
}

nav ul li ul {
  width: 200px;
  display: none;
  top: 100%;
  left: 0;
  position: absolute;
  padding: 10px;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
}

nav ul li ul li {
  margin: 0;
  padding: 0;
}

nav ul li ul a {
  margin: 0;
  padding: 10px;
  background-color: transparent;
  line-height: 16px;
  /* text-transform: uppercase; */
  text-align: left;
}

nav ul li ul a {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  transition: 0.4s ease all;
  -moz-transition: 0.4s ease all;
  -webkit-transition: 0.4s ease all;
}

nav ul li ul a * {
  color: #000;
  transition: 0.4s ease all;
  -moz-transition: 0.4s ease all;
  -webkit-transition: 0.4s ease all;
}

nav ul li ul a:focus,
nav ul li ul a:active,
nav ul li ul a:visited,
nav ul li ul a:link {
  color: #000;
}

nav ul li ul a:focus *,
nav ul li ul a:active *,
nav ul li ul a:visited *,
nav ul li ul a:link * {
  color: #000;
}

nav ul li ul a.active {
  color: #2c99c4;
}

nav ul li ul a.active * {
  width: 300px;
  color: #2c99c4;
}

nav ul li ul a:hover {
  color: #2c99c4;
}

nav ul li ul a:hover * {
  color: #2c99c4;
}

/* Add these styles for the top-level active links */
nav ul li a.active {
  color: #2c99c4;
}

nav ul li a.active * {
  color: #2c99c4;
}


nav ul li:hover>a {

  color: #2c99c4;
}

nav ul li:hover>ul {
  display: block;
}

nav ul li.active>a {
  color: #2c99c4;
}

nav>ul>li {
  width: auto;
  float: left;
  margin: 10px;
  justify-content: center;
  display: flex;
  position: relative;
}


.humberg {
  display: none;
}

.humberg {
  position: relative;
  width: 30px;
  height: 30px;
  float: left;
  top: 12px;
}

.humberg .icon-bar {
  left: 0;
  position: absolute;
  display: block;
  height: 4px;
  background-color: #282828;
  transform: rotate(0);
  -moz-transform: rotate(0);
  -webkit-transform: rotate(0);
}

.humberg .icon-bar-a {
  width: 30px;
  top: 5px;
}

.humberg .icon-bar-b {
  height: 4px;
  width: 20px;
  top: 15px;
}

.humberg .icon-bar-c {
  width: 10px;
  bottom: 0;
}

.humberg.active .icon-bar-a {
  width: 25px;
  top: 13px;
  transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.humberg.active .icon-bar-b {
  background-color: transparent !important;
}

.humberg.active .icon-bar-c {
  width: 25px;
  bottom: 13px;
  transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

nav li>span.drop-down {
  display: none;
}

nav li>span.drop-down {
  width: 46px;
  height: 46px;
  background: rgba(34, 34, 34, 0.09);
  top: 0;
  right: 0;
  position: absolute;
  z-index: 2;
}

nav li>span.drop-down:after {
  width: 15px;
  height: 14px;
  content: '+';
  font-size: 14px;
  line-height: 14px;
  color: #212121;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  margin: auto;
  text-align: center;
}

nav li.active>span.drop-down {
  background: rgba(34, 34, 34, 0.2);
}

nav li.active>span.drop-down:after {
  content: '-';
  font-size: 23px;
  line-height: 10px;
}

.swipe-layer {
  width: 10px;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 99999;
  background-color: transparent;
}

.logo img {
  max-width: 46%;
}



footer {
  padding: 50px 0;
  background-color: #eee;
}

p,
h2,
h3 {
  text-align: center;
  margin: 20px 0;
}

Section {
  display: inline-block;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #f5f5f5;
}

Section:nth-child(even) {
  background-color: #fff;
}

Section h2 {
  padding: 50px 0;
}

@media (max-width: 767px) {
  header {
    background-color: #eee;
    width: 100%;
    top: 0rem;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 10;
  }

  header .container {
    width: 100%;
    position: relative;
    padding: 0 15px;
    margin: 0 auto;
  }

  .hamburger {
    cursor: pointer;
    font-size: 24px;
    padding: 2rem;
  }

  .hamburger {
    display: block;
  }

  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 95px;
    right: 0;
    background-color: #ae2585;
    width: 100%;
  }

  .menu.open {
    display: flex;
  }

  .menu li {
    text-align: center;
    padding: 15px;
  }

  nav>ul>li {
    width: 91%;
    margin: 10px;
    justify-content: start;
    display: flex;
    position: relative;
  }

  nav ul li a {
    display: block;
    margin: 0;
    font-size: 25px;
    padding: 30px 15px;
    line-height: 16px;
    line-height: 20px;
    position: relative;
  }

  nav {
    overflow-y: auto;
  }

  nav::-webkit-scrollbar-track {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: #ffffff;
    width: 11px;
  }

  nav::-webkit-scrollbar {
    width: 11px;
    background-color: #ffffff;
  }

  nav::-webkit-scrollbar-thumb {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background-color: #d0cfcf;
  }

  nav li>span.drop-down {
    display: block;
  }

  .social {
    display: none;
  }

  .content {
    position: absolute;
    font-size: 12px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    padding: 20px;
  }

  .content p {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    width: 50%;
  }

  .content h1 {
    text-align: center;
    font-weight: 900;
    color: #ffffff;
    font-size: 15px;
  }

  body {
    overflow-x: hidden;
    width: auto;
  }

  body main,
  body footer,
  body .swipe-layer,
  body nav {
    transition: 1s ease all;
    -moz-transition: 1s ease all;
    -webkit-transition: 1s ease all;
  }

  body main,
  body footer,
  body .swipe-layer {
    top: 0;
    left: 0;
  }

  body main,
  body footer {
    position: relative;
  }

  body.menu-active main,
  body.menu-active footer,
  body.menu-active .swipe-layer {
    left: 241px;
  }

  body.menu-active nav {
    left: 0;
  }

  body.menu-active .swipe-layer {
    width: 100%;
  }

  header .container {
    text-align: center;
  }

  .logo {
    width: 50%;
    float: none;
    display: inline-block;
    padding: 10px 0 5px;
    margin-left: 62px;
    margin-right: auto;
  }

}

@media (max-width: 393px) {
  .menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 85px;
    right: 0;
    background-color: #ae2585;
    width: 100%;
  }

  .menu.open {
    display: flex;
  }

  .menu li {
    text-align: center;
    padding: 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 1120px) {
  header {
    background-color: #eee;
    width: 100%;
    top: 2.3rem;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 10;
  }

  header .container {
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
  }

  .logo img {
    max-width: 43%;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    width: 320px;
    float: left;
    display: flex;
    margin-bottom: -44px;

  }
}

@media (min-width: 1280px) {
  header {
    background-color: #eee;
    width: 100%;
    top: 2.3rem;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 10;
  }

  header .container {
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
  }

  .logo img {
    max-width: 43%;
    margin-left: auto;
    margin-right: auto;
  }

  .logo {
    width: 320px;
    float: left;
    display: flex;
    margin-bottom: -44px;

  }
}

@media (min-width: 48em) {


  .header li a {
    padding: 20px 30px;
    color: #000;
    font-weight: bolder;
    text-decoration: none;
  }

  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }

  .header .menu-icon {
    display: none;
  }
}

@media (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }
}