.commingSoon {
    width: 100%;
    display: flex;
}

.srcCommingSoom {
    width: 50%;
    margin-top: 20%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
}