.container {

    display: flex;
    flex-direction: column;
    width: 80%;
    min-height: 150vh;
    margin-left: auto;
    margin-right: auto;
}

.google-map {
    width: 100%;
    float: left;
    position: relative;
}

.google-map iframe {
    width: 100%;
    height: 485px;
    border: 0px;
}

.cform {
    display: block;
    position: relative;
    margin-top: 5%;
}

.pl-15 {
    padding-left: 15px;
}

.pr-15 {
    padding-right: 15px;
}


.cform .fsubmit {
    width: 100%;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    background: #2c99c7;
    border: 0px;
    height: 55px;
    line-height: 55px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.cform .fsubmit:hover {
    background: #33691e;
    color: #fff;
}

.contact-form h3 {
    font-weight: 700;
    margin: 0 0 25px;
}

.cInfo {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    padding: 30px;
    margin-top: 5%;
    font-family: 'Montserrat', sans-serif;
}

.cInfo:hover {
    box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, .25);
}

.contact-map-info h6,
.cInfo h6 {
    font-weight: 600;
    color: #333333;
    font-size: 20px;
}

.contact-map-info p,
.cInfo p {
    margin: 0px;
    color: #555555;
    font-size: 16px;
    line-height: 26px;
}

.contact-info {
    margin-top: 70px;
}

.contact-info h3 {
    font-weight: 700;
    margin: 0 0 25px;
}

.contact-form {
    width: 100%;
    float: left;
}

.mb60 {
    margin-bottom: 60px;
}

.contact-map-info {
    background: #fff;
    border-radius: 5px;
    width: 350px;
    position: absolute;
    right: 50px;
    top: 50px;
    padding: 30px;
}

.contact-map-info ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.contact-map-info ul li {
    margin-bottom: 30px;
}

.contact-map-info ul li:last-child {
    margin: 0px;
}

.contactPage {
    min-height: 100vh;
    padding: 80px 0;
    background: #f7f7f7;
}

.contactForm {
    font-family: 'Montserrat', sans-serif;
    position: relative;
    top: 30%;
    text-align: center;
}

.googleMap {
    position: relative;
    top: 30%;
    text-align: center;
}

.contactInfo {
    font-family: 'Montserrat', sans-serif;
    position: relative;
    top: 40%;
    text-align: center;
}

.contactInfo h3 {

    font-family: 'Montserrat', sans-serif;
}