@media only screen and (max-width: 320px) {
    .title {
        color: #000;
        text-align: center;
        margin-top: 7%;
        margin-bottom: 2%;
        font-family: 'Montserrat', sans-serif;
    }

    .licencesProfessionnelles {

        padding: 20px;
        text-align: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Montserrat', sans-serif;
    }

    .tabTitles {
        background-color: #f5f5f5;
        border: 1px solid gray;
        border-radius: 4px;
        padding: 10px;
    }


    .tabTitles button {
        background: linear-gradient(90deg, rgba(206, 35, 133, 1) 0%, rgba(163, 14, 118, 0.9248949579831933) 50%, rgba(206, 35, 133, 1) 100%);
        border: none;
        padding: 10px 20px;
        margin-top: 4%;
        cursor: pointer;
        font-size: 1em;
        color: #fff;
        font-weight: 700;
        border-radius: 6px;
        display: list-item;
        width: 100%;
    }

    .tabTitles button:hover {
        color: #000;
    }

    .active {
        background: #fff;
        color: #000;
        border: none;
        padding: 10px 20px;
        margin-right: 40px;
        cursor: pointer;
        font-size: 1em;
        font-weight: 700;
        border-radius: 6px;
    }

    .image {
        margin-top: 10%;
        width: 100%;
    }

    .cards {
        margin: 20px 0 20px 0;

    }

    .title {
        font-size: 16px;
    }
}

@media only screen and (min-width: 321px) and (max-width: 480px) {
    .title {
        color: #000;
        text-align: center;
        margin-top: 7%;
        margin-bottom: 2%;
        font-family: 'Montserrat', sans-serif;
    }

    .licencesProfessionnelles {

        padding: 20px;
        text-align: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Montserrat', sans-serif;
    }

    .tabTitles {
        background-color: #f5f5f5;
        border: 1px solid gray;
        border-radius: 4px;
        padding: 10px;
    }

    .tabButton {
        background: linear-gradient(90deg, rgba(206, 35, 133, 1) 0%, rgba(163, 14, 118, 0.9248949579831933) 50%, rgba(206, 35, 133, 1) 100%);
        border: none;
        padding: 10px 20px;
        margin-top: 4%;
        cursor: pointer;
        font-size: 1em;
        color: #fff;
        font-weight: 700;
        border-radius: 6px;
        display: list-item;
        width: 100%;
    }

    .tabButton:hover {
        color: #000;
        /* Change color on hover */
    }

    .active {
        background: #ffffff00;
        /* Change background color when active */
        color: #000;
        /* Change text color to black when active */
    }

    .image {
        margin-top: 10%;
        width: 100%;
    }

    .cards {
        margin: 20px 0 20px 0;

    }

    .title {
        font-size: 16px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
    .title {
        color: #000;
        text-align: center;
        margin-top: 7%;
        margin-bottom: 2%;
        font-family: 'Montserrat', sans-serif;
    }

    .licencesProfessionnelles {

        padding: 20px;
        text-align: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Montserrat', sans-serif;
    }

    .tabTitles {
        background-color: #f5f5f5;
        border: 1px solid gray;
        border-radius: 4px;
        padding: 10px;
    }

    .tabButton {
        background: linear-gradient(90deg, rgba(206, 35, 133, 1) 0%, rgba(163, 14, 118, 0.924) 50%, rgba(206, 35, 133, 1) 100%);
        border: none;
        padding: 10px 20px;
        margin-right: 40px;
        cursor: pointer;
        font-size: 1em;
        color: #fff;
        /* Default text color */
        font-weight: 700;
        border-radius: 6px;
        transition: color 0.3s, background 0.3s;
        /* Smooth transition */
    }

    .tabButton:hover {
        color: #000;
        /* Change color on hover */
    }

    .active {
        background: #ffffff00;
        /* Change background color when active */
        color: #000;
        /* Change text color to black when active */
    }

    .image {
        margin-top: 10%;
        width: 100%;
    }

    .cards {
        margin: 20px 0 20px 0;

    }

    .title {
        font-size: 16px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .title {
        color: #000;
        text-align: center;
        margin-top: 7%;
        margin-bottom: 2%;
        font-family: 'Montserrat', sans-serif;
    }

    .licencesProfessionnelles {

        padding: 20px;
        text-align: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Montserrat', sans-serif;
    }

    .tabTitles {
        background-color: #f5f5f5;
        border: 1px solid gray;
        border-radius: 4px;
        padding: 10px;
    }

    .tabTitles button {
        background: linear-gradient(90deg, rgba(206, 35, 133, 1) 0%, rgba(163, 14, 118, 0.9248949579831933) 50%, rgba(206, 35, 133, 1) 100%);
        border: none;
        padding: 10px 20px;
        margin-right: 40px;
        cursor: pointer;
        font-size: 1em;
        color: #fff;
        font-weight: 700;
        border-radius: 6px;
        transition: color 0.3s, background 0.3s;
    }

    .tabTitles button:hover {
        color: #000;
    }

    .active {
        background: #fff;
        color: #000;
        border: none;
        padding: 10px 20px;
        margin-right: 40px;
        cursor: pointer;
        font-size: 1em;
        font-weight: 700;
        border-radius: 6px;
    }

    .image {
        margin-top: 10%;
        width: 100%;
    }

    .cards {
        margin: 20px 0 20px 0;

    }

    .title {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .title {
        color: #000;
        text-align: center;
        margin-top: 7%;
        margin-bottom: 2%;
        font-family: 'Montserrat', sans-serif;
    }

    .licencesProfessionnelles {

        padding: 20px;
        text-align: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Montserrat', sans-serif;
    }

    .tabTitles {
        background-color: #f5f5f5;
        border: 1px solid gray;
        border-radius: 4px;
        padding: 10px;
    }

    .tabTitles button {
        background: linear-gradient(90deg, rgba(206, 35, 133, 1) 0%, rgba(163, 14, 118, 0.9248949579831933) 50%, rgba(206, 35, 133, 1) 100%);
        border: none;
        padding: 10px 20px;
        margin-right: 40px;
        cursor: pointer;
        font-size: 1em;
        color: #fff;
        font-weight: 700;
        border-radius: 6px;
        transition: color 0.3s, background 0.3s;
    }

    .tabTitles button:hover {
        color: #000;
    }

    .active {
        background: #fff;
        color: #000;
        border: none;
        padding: 10px 20px;
        margin-right: 40px;
        cursor: pointer;
        font-size: 1em;
        font-weight: 700;
        border-radius: 6px;
    }

    .image {
        margin-top: 10%;
        width: 100%;
    }

    .cards {
        margin: 20px 0 20px 0;

    }

    .title {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1201px) {
    .title {
        color: #000;
        text-align: center;
        margin-top: 7%;
        margin-bottom: 2%;
        font-family: 'Montserrat', sans-serif;
    }

    .licencesProfessionnelles {

        padding: 20px;
        text-align: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Montserrat', sans-serif;
    }

    .tabTitles {
        background-color: #f5f5f5;
        border: 1px solid gray;
        border-radius: 4px;
        padding: 10px;
    }

    /* .tabTitles button {
        background: linear-gradient(90deg, rgba(206, 35, 133, 1) 0%, rgba(163, 14, 118, 0.9248949579831933) 50%, rgba(206, 35, 133, 1) 100%);
        border: none;
        padding: 10px 20px;
        margin-right: 40px;
        cursor: pointer;
        font-size: 1em;
        color: #fff;
        font-weight: 700;
        border-radius: 6px;
        transition: color 0.3s, background 0.3s;
    }

    .tabTitles button:hover {
        color: #000;
    }

    .active {
        background: #fff;
        color: #000;
        border: none;
        padding: 10px 20px;
        margin-right: 40px;
        cursor: pointer;
        font-size: 1em;
        font-weight: 700;
        border-radius: 6px;
    } */
    .tabButton {
        background: linear-gradient(90deg, rgba(206, 35, 133, 1) 0%, rgba(163, 14, 118, 0.924) 50%, rgba(206, 35, 133, 1) 100%);
        border: none;
        padding: 10px 20px;
        margin-right: 40px;
        cursor: pointer;
        font-size: 1em;
        color: #fff;
        /* Default text color */
        font-weight: 700;
        border-radius: 6px;
        transition: color 0.3s, background 0.3s;
        /* Smooth transition */
    }

    .tabButton:hover {
        color: #000;
        /* Change color on hover */
    }

    .active {
        background: #ffffff00;
        /* Change background color when active */
        color: #000;
        /* Change text color to black when active */
    }

    .image {
        margin-top: 10%;
        width: 100%;
    }

    .cards {
        margin: 20px 0 20px 0;

    }

    .title {
        font-size: 16px;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi) {
    .title {
        color: #000;
        text-align: center;
        margin-top: 7%;
        margin-bottom: 2%;
        font-family: 'Montserrat', sans-serif;
    }

    .licencesProfessionnelles {

        padding: 20px;
        text-align: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        font-family: 'Montserrat', sans-serif;
    }

    .tabTitles {
        background-color: #f5f5f5;
        border: 1px solid gray;
        border-radius: 4px;
        padding: 10px;
    }

    .tabTitles button {
        background: linear-gradient(90deg, rgba(206, 35, 133, 1) 0%, rgba(163, 14, 118, 0.9248949579831933) 50%, rgba(206, 35, 133, 1) 100%);
        border: none;
        padding: 10px 20px;
        margin-right: 40px;
        cursor: pointer;
        font-size: 1em;
        color: #fff;
        font-weight: 700;
        border-radius: 6px;
    }

    .tabButton .active {
        background: linear-gradient(90deg, rgba(206, 35, 133, 1) 0%, rgba(163, 14, 118, 0.9248949579831933) 50%, rgba(206, 35, 133, 1) 100%);
        border: none;
        padding: 10px 20px;
        margin-right: 40px;
        cursor: pointer;
        font-size: 1em;
        color: #070707;
        font-weight: 700;
        border-radius: 6px;
    }

    .active {
        border-bottom: 2px solid teal;
        /* Or any other styling for active tabs */
    }

    .image {
        margin-top: 10%;
        width: 100%;
    }

    .cards {
        margin: 20px 0 20px 0;

    }

    .title {
        font-size: 16px;
    }
}