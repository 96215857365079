/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

.border-md {
    border-width: 2px;
}

.btn-facebook {
    background: #405d9d;
    border: none;
}

.btn-facebook:hover,
.btn-facebook:focus {
    background: #314879;
}

.btn-twitter {
    background: #42aeec;
    border: none;
}

.btn-twitter:hover,
.btn-twitter:focus {
    background: #1799e4;
}

/*
  *
  * ==========================================
  * FOR DEMO PURPOSES
  * ==========================================
  *
  */
.container {

    display: flex;
    flex-direction: column;
    width: 80%;
    min-height: 120vh;
    margin-left: auto;
    margin-right: auto;
}

body {
    min-height: 100vh;
    font-family: 'Montserrat', sans-serif;
}

.form-control:not(select) {
    padding: 1.5rem 0.5rem;
}

select.form-control {
    height: 52px;
    padding-left: 0.5rem;
}

.form-control::placeholder {
    color: #ccc;
    font-weight: bold;
    font-size: 0.9rem;
}

.form-control:focus {
    box-shadow: none;
}

.description {
    position: absolute;
}

.signup {
    margin-left: 5%;
    border-left: 1px solid;
    border-left-style: groove;
}

.signup p {
    font-size: 15px;
    font-weight: 700;
    color: #000;
}

.signup h3 p {
    font-size: 30px;
    font-weight: 700;
    display: inline;
}

.all {
    display: flex;
    position: relative;
    top: 11rem;
}

.forms {
    left: 10%;
    position: relative;
    height: auto;
}

.button {
    text-align: center;
}

.image-container {
    position: relative;
    overflow: hidden;
}

.image-container img {
    position: relative;
    transform: translateX(100%);
    opacity: 0;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.image-container img.loaded {
    transform: translateX(0);
    opacity: 1;
}

.description {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    padding: 20px;
}

.description h5 {
    color: #000;
    font-weight: 400;
}

.description h2 {
    color: #000;
    font-weight: 700;
    margin-right: 35%;
    padding: 2%;
}

.par {
    margin-top: 7%;
}

/* @media (min-width:320px) and (max-width:479px) {
    .backgroundImage {
        display: none;
    }

    .signup {
        margin-left: 0%;
        border-left: 1px solid;
        border-left-style: groove;
    }

    .signup p {
        font-size: 15px;
        font-weight: 700;
        color: #000;
    }

    .signup h3 p {
        font-size: 30px;
        font-weight: 700;
        display: inline;
    }
} */



@media (max-width: 768px) {
    .backgroundImage {
        display: none;
    }

    .signup {
        margin-left: 0%;
        border-left: 1px solid;
        border-left-style: groove;
    }

    .signup p {
        font-size: 15px;
        font-weight: 700;
        color: #000;
    }

    .signup h3 p {
        font-size: 27px;
        font-weight: 700;
        display: inline;
    }

    .forms {
        top: 1%;
        left: 0%;
        position: relative;
        height: auto;
    }
}